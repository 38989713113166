import request from "@/utils/request";

export function getNearestExamination(params) {
    return request({
        url: "/examination_nearest",
        method: "get",
        params
    });
}

export function resetFormSession(data) {
  return request({
    url: "/examinations_form/reset",
    method: "post",
    data,
  });
}
export function calculate(id, data) {
  return request({
    url: "/calculate/" + id,
    method: "post",
    data,
  });
}
export function getOriginalExams(id) {
  return request({
    url: "/examinations/" + id + "/original-exams",
    method: "get",
  });
}
export function getMetadata(id) {
  return request({
    url: "/examinations/" + id + "/metadata",
    method: "get",
  });
}
export function getResult(id, params) {
  return request({
    url: "/examinations/" + id + "/result",
    method: "get",
    params,
  });
}

export function backupExamination(data) {
  return request({
    url: "/examinations/backup",
    method: "post",
    responseType: "blob",
    timeout: 0,
    data,
  });
}
